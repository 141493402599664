import "select2"

$(function () {
  $(".dropdown-select").each(function () {
    $(this).select2({
      minimumResultsForSearch: -1,
      placeholder: $("option:first-child", this).text(),
      allowClear: false,
    })
  })
  $(".dropdown-select-with-search").each(function () {
    $(this).select2({
      minimumResultsForSearch: 1,
      language: {
        noResults: () => $(this).data("no-results"),
      },
    })
  })
  $(".dropdown-multi-select").each(function () {
    $(this)
      .select2({
        placeholder: $("option:first-child", this).text(),
        multiple: true,
        language: {
          noResults: () => $(this).data("no-results"),
        },
      })
      .on("change", function () {
        if ($(this).val() && $(this).val().length) {
          $(this)
            .next(".select2-container")
            .find(".select2-search__field")
            .attr("placeholder", $("option:first-child", this).text())
        }
      })
      .trigger("change")
  })

  $(".dropdown-select").parent().find('b[role="presentation"]').hide()
  $(".dropdown-select")
    .parent()
    .find(".select2-selection__arrow")
    .append('<i class="fa fa-angle-down"></i>')
})
